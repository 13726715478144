<template>
    <div class="main-box">
        <div class="container-login" v-show="type && !showScanCode">
            <form>
                <span class="text">登 录</span>
                <input class="form__input" v-model="loginForm.username" type="text" placeholder="账号" />
                <input class="form__input" v-model="loginForm.password" type="password" placeholder="密码" />
                <div class="primary-btn" @click="login">立即登录</div>
            </form>
        </div>
        <div class="container-form" v-show="!type && !showScanCode">
            <div class="use">
                <div class="top">
                    <img src="@/assets/phone/coupon_icon.png" style="width: 50px;" />
                    <text style="font-weight: bold;margin-top: 20px;">一张优惠券可用</text>
                </div>
                <div class="item">
                    <div style="font-weight: bold;">优惠券名称：</div>
                    <div style="font-weight: bold;">{{ equityInfos.equityName }}</div>
                </div>
                <div style="width:90%;background-color:#999999; height: 1px;"></div>
                <div class="item">
                    <div>有效日期：</div>
                    <div>2022-01-03 - 2022-10-23</div>
                </div>
                <div style="width:90%;background-color:#999999; height: 1px;"></div>

                <div class="item">
                    <div>使用日期：</div>
                    <div>周一、周二、周三、周四、周五、周六、周末</div>
                </div>
                <div style="width:90%;background-color:#999999; height: 1px;"></div>

                <div class="item">
                    <div>使用时间：</div>
                    <div>00:00-23:59</div>
                </div>
                <div style="width:90%;background-color:#999999; height: 1px;"></div>

                <div class="item">
                    <div>使用提醒：</div>
                    <div>请在结账出示服务人员进行核销</div>
                </div>
                <div style="width:90%;background-color:#999999; height: 1px;"></div>
            </div>

            <div class="footer" @click="confirmHandle" v-show="!consumer">
                <div class="btn">确认核销</div>
            </div>
            <!-- <div class="footer" @click="scanCode" v-show="!consumer">
          <div class="btn">继续扫描</div>
        </div> -->
        </div>
        <el-dialog title="" :visible.sync="consumer" width="80%" top="35vh" :show-close="false" :center="true" append-to-body>
            <div style="text-align: center;font-size: 20px;">{{ tips }}</div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="closeDialog" style="width: 100%;">确 定</el-button>
            </span>
        </el-dialog>
        <!-- <BarScan @ok="getResult" @err="geterror" v-show="showScanCode"></BarScan> -->
    </div>
</template>

<script>
import axios from "axios";
// import BarScan from "@/components/qrcode.vue";
export default {
    name: "LoginBox",
    // components: {
    //   BarScan,
    // },
    data() {
        return {
            id: "",
            type: true,
            storeId: "",
            equityInfos: {},
            consumer: false,
            loginForm: {
                username: "",
                password: "",
            },
            result: "",
            showScanCode: false,
            tips: "核销成功",
        };
    },
    created() {
        this.id = decodeURIComponent(window.location.href)
            .split("?id=")[1]
            .split("&")[0];
        const codeTime = Number(decodeURIComponent(window.location.href).split(
            "&time="
        )[1]);
        const nowTime = new Date().getTime();
        if (nowTime - codeTime > 600000) {
            this.consumer = true;
            this.tips = "二维码已失效";
        } else {
            const loginDate = localStorage.getItem("loginId")
                ? localStorage.getItem("loginId").split("-")[1]
                : "";
            if (loginDate == new Date().getDate()) {
                this.type = false;
            } else {
                this.type = true;
            }
            this.getById(this.id);
        }
    },
    mounted() {
        document.getElementsByClassName('main-box')[0].style["transform"] =
            "scale(" + 1240 / window.screen.width + ")";
    },
    methods: {
        async getById(id) {
            await axios
                .get(
                    `https://bizapp.zyjkjt.net/member/app/account/user/equity/getById?id=${id}`
                )
                .then(async (res) => {
                    if (res.data.data.length) {
                        this.equityInfos = res.data.data[0];
                        this.consumer = false;
                    } else {
                        this.$toast("二维码错误!");
                    }
                });
        },
        login() {
            axios
                .post(`https://bizapp.zyjkjt.net/member/app/veriLogin`, {
                    username: this.loginForm.username,
                    password: this.loginForm.password,
                })
                .then((res) => {
                    if (res.data.code == 200) {
                        this.$toast("登录成功!");
                        localStorage.setItem(
                            "loginId",
                            this.id + "-" + new Date().getDate()
                        );
                        localStorage.setItem("storeId", res.data.data.id);
                        this.type = false;
                        this.storeId = res.data.data.id;
                        this.loginForm.contactPhone = res.data.data.contactPhone
                    } else {
                        this.$toast(res.data.msg);
                    }
                });
        },
        confirmHandle() {
            axios
                .post(
                    `https://bizapp.zyjkjt.net/member/app/account/user/equity/consumer`,
                    {
                        nucleusCode: this.equityInfos.nucleusCode,
                        count: 1,
                        storeId: localStorage.getItem("storeId")
                    }
                )
                .then((res) => {
                    this.consumer = true;
                    if (res.data.code == 200) {
                        this.tips = "核销成功";
                    } else {
                        this.tips = "核销失败";
                    }
                });
        },
        closeDialog() {
            this.weixinClosePage();
            this.weixin_ClosePage();
        },
        weixinClosePage() {
            if (typeof WeixinJSBridge == "undefined") {
                if (document.addEventListener) {
                    document.addEventListener(
                        "WeixinJSBridgeReady",
                        this.weixin_ClosePage(),
                        false
                    );
                } else if (document.attachEvent) {
                    document.attachEvent("WeixinJSBridgeReady", this.weixin_ClosePage());
                    document.attachEvent(
                        "onWeixinJSBridgeReady",
                        this.weixin_ClosePage()
                    );
                }
            } else {
                this.weixin_ClosePage();
            }
        },
        weixin_ClosePage() {
            WeixinJSBridge.call("closeWindow");
        },
        scanCode() {
            this.showScanCode = true;
        },
        getResult(res) {
            const id = res.split("?id=")[1];
            this.getById(id);
            this.showScanCode = false;
        },
        geterror() {
            this.showScanCode = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.main-box {
    transform-origin: 0 0;
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: #fff;
    border-radius: 12px;
    overflow: hidden;

    .container-login {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        transition: all 1.25s;

        form {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            height: 100%;
            color: #a0a5a8;

            .text {
                color: #5e5d5d;
                margin-bottom: 50px;
                font-size: 30px;
            }

            .form__input {
                width: 50vw;
                height: 40px;
                margin: 4px 0;
                padding-left: 25px;
                font-size: 14px;
                letter-spacing: 0.15px;
                border: none;
                outline: none;
                background-color: #fff;
                transition: 0.25s ease;
                border-radius: 8px;
                box-shadow: inset 2px 2px 4px #d1d9e6, inset -2px -2px 4px #f9f9f9;

                &::placeholder {
                    color: #a0a5a8;
                }
            }
        }
    }

    .container-form {
        display: flex;
        background-color: #fff;
    }

    .primary-btn {
        width: 180px;
        height: 50px;
        border-radius: 25px;
        margin-top: 50px;
        text-align: center;
        line-height: 50px;
        font-size: 18px;
        letter-spacing: 2px;
        background-color: #4b70e2;
        color: #f9f9f9;
        cursor: pointer;
        box-shadow: 8px 8px 16px #d1d9e6, -8px -8px 16px #f9f9f9;
    }

    .use {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .top {
            width: 100%;
            height: 130px;
            background-color: antiquewhite;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .item {
            width: 90%;
            height: 60px;
            display: flex;
            font-size: 13px;
            flex-direction: row;
            align-items: center;
        }
    }

    .footer {
        width: 100%;
        height: 70px;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        bottom: 0;

        .btn {
            width: 90%;
            height: 50px;
            border-radius: 20px;
            background-color: #ff6050;
            color: white;
            font-size: 13px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

/deep/ .el-dialog {
    border-radius: 20px;
}
</style>